/* global om */
import { request } from 'graphql-request';
import fetch from 'isomorphic-unfetch';
import uniq from 'lodash/uniq';
import * as moment from 'moment';
import SiteSettings from '~/siteSettings';
import uuidv1 from "uuid/v1";

// GQL
import GET_CONTENT_PATHS from '~/graphql/GET_CONTENT_PATHS.graphql';
import GET_ALIAS from '../graphql/GET_ALIAS.graphql'; // @NOTE: This is bizare but root path resolution is failing for this import only
import GET_SHOWFINDER from '~/graphql/GET_SHOWFINDER.graphql';
import GET_BLOCK_FROM_QUEUE from '~/graphql/GET_BLOCK_FROM_QUEUE.graphql';
import GET_CONTENT_TYPE from '~/graphql/GET_CONTENT_TYPE.graphql';
import GET_TAXONOMY_TERMS from '~/graphql/GET_TAXONOMY_TERMS.graphql';
import GET_GALLERIES from '~/graphql/GET_GALLERIES.graphql';
import GET_SITE_SETTINGS from '~/graphql/GET_SITE_SETTINGS.graphql';
import GET_VIEW_RESULTS from '~/graphql/GET_VIEW_RESULTS.graphql';

export const urlWithQuery = (url, queryString) => {
  if (url) {
    if (url.indexOf('?') === -1) {
      return `${url}?${queryString}`;
    }

    return `${url}&${queryString}`;
  }
  return url;
};

/* eslint-disable */
// Get a property if exists
export const get = (obj, key) => {
  return key.split('.').reduce(function(o, x) {
    return (typeof o === 'undefined' || o === null) ? o : o[x];
  }, obj);
}

// Check if object has property
export const has = (obj, key) => {
  return key.split('.').every(function(x) {
    if(typeof obj != 'object' || obj === null || ! x in obj)
      return false;
    obj = obj[x];
    return true;
  });
}

export const chunkBy = (arr, by) => {
  const mutatedArr = arr.concat();
  const res = [];

  // Known Sets
  for(let i = 0; i < by.length; i++) {
    const set = [];
    const len = by[i]
      ? by[i]
      : by[by.length - 1];

    for(let j = 0; j < len; j++) {
      if(mutatedArr.length > len){
        set.push(mutatedArr.shift());
      }
    }

    if(set.length > 0) {
      res.push(set);
    }
  }

  // Rest (always even)
  for(let i = 0; i < mutatedArr.length; i++) {
    const set = [];
    const len = by[by.length - 1];

    for(let j = 0; j < len; j++) {
      if(mutatedArr.length > len){
        set.push(mutatedArr.shift());
      }
    }

    if(set.length > 0) {
      res.push(set);
    }
  }

  // Uneven remainder
  if(mutatedArr.length > 0) {
    res.push(mutatedArr);
  }

  return res;
}

export const supportsPassive = () => {
  let supportsPassive = false;
  try {
    const opts = Object.defineProperty({}, 'passive', {
      get: () => {
        supportsPassive = true;
      }
    });
    window.addEventListener('testPassive', null, opts);
    window.removeEventListener('testPassive', null, opts);
  } catch (e) {
    console.error(e);
  }

  return supportsPassive;
}
/* eslint-enable */

export const getIcon = typeName => {
  let icon = '';

  switch(typeName.toLowerCase()) {
    case 'article':
      icon = '/img/icons/article.svg';
      break;
    case 'gallery':
      icon = '/img/icons/gallery.svg';
      break;
    case 'recipe':
      icon = '/img/icons/recipe.svg';
      break;
    case 'vertical_page':
      icon = '/img/icons/vertical_page.svg';
      break;
    case 'video':
      icon = '/img/icons/video.svg';
      break;
    case 'plug':
      icon = '/img/icons/plug.svg';
      break;
    default:
      icon = '/img/icons/generic.svg';
      break;
  }

  return icon;
}

export const getDate = (date) => {
  const offset = moment().isDST() ? -420 : -480;
  return moment(date).utcOffset(offset).format('MMMM DD, YYYY');
}

export const stripTags = (str) => {
  return str.replace(/(<([^>]+)>)/gi, '');
}

export const mobileCheck = (userAgent = null) => {
  if(typeof navigator !== 'undefined' && typeof navigator.userAgent !== 'undefined') {
    userAgent = navigator.userAgent
  }
  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent) ) {
    return true;
  }
  return false;
}

export const trackPageview = () => {
  if (typeof om !== 'undefined' && window.utag_data) {
    setTimeout(() => {
      om.trackView();
    }, 250);
  } else {
    setTimeout(trackPageview, 250);
  }
}

export const verifyAnalytics = async (limit, duration) => {
  let attempts = 0;

  // eslint-disable-next-line consistent-return
  return new Promise(function check(resolve) {
    attempts += 1;

    if (window.utag_data && window.om && window.vtg && window.utag) {
      return resolve(true);
    }

    if(attempts === limit) {
      return resolve(false);
    }

    setTimeout(() => check(resolve), duration);
  });
  /* eslint-enable no-undef */
}

export const verifyOm = async (limit, duration) => {
  let attempts = 0;

  // eslint-disable-next-line consistent-return
  return new Promise(function check(resolve) {
    attempts += 1;

    if (window.om && window.utag_data) {
      return resolve(true);
    }

    if(attempts === limit) {
      return resolve(false);
    }

    setTimeout(() => check(resolve), duration);
  });
  /* eslint-enable no-undef */
}

export const verifyGlobalVars = async (limit, duration, globalVars) => {
  let attempts = 0;

  // eslint-disable-next-line consistent-return
  return new Promise(function check(resolve) {
    attempts += 1;

    if (globalVars.every((globalVar) => Object.prototype.hasOwnProperty.call(window, globalVar))) {
      return resolve(true);
    }

    if(attempts === limit) {
      return resolve(false);
    }

    setTimeout(() => check(resolve), duration);
  });
}

export const verifyVarIsTruthy = async (limit, duration, variable) => {
  let attempts = 0;

  // eslint-disable-next-line consistent-return
  return new Promise(function check(resolve) {
    attempts += 1;

    if (variable) {
      return resolve(true);
    }

    if(attempts === limit) {
      return resolve(false);
    }

    setTimeout(() => check(resolve), duration);
  });
}

export const checkTwitterApi = async (limit, duration) => {
  let attempts = 0;

  // eslint-disable-next-line consistent-return
  return new Promise(function check(resolve) {
    attempts += 1;

    if (window.twttr.widgets) {
      return resolve(true);
    }

    if(attempts === limit) {
      return resolve(false);
    }

    setTimeout(() => check(resolve), duration);
  });
  /* eslint-enable no-undef */
}

export const checkIgApi = async (limit, duration) => {
  let attempts = 0;

  // eslint-disable-next-line consistent-return
  return new Promise(function check(resolve) {
    attempts += 1;

    if (window.instgrm) {
      return resolve(true);
    }

    if(attempts === limit) {
      return resolve(false);
    }

    setTimeout(() => check(resolve), duration);
  });
  /* eslint-enable no-undef */
}

export const processSecurePath = (path) => {
  return path.replace('http', 'https');
}

export const isExternal = (path) => {
  return new RegExp('^(?:[a-z]+:)?//', 'i').test(path);
}

export const getStructuredData = (baseUrl, path, content) => {
  const schemaObject = {
    '@context': 'http://schema.org',
    articleSection: 'News',
    dateModified:  content.field_display_date,
    headline: content.title,
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${ baseUrl }${ path }`
    },
    datePublished: content.field_display_date,
    publisher: {
      name: SiteSettings.siteName,
      '@type': 'Organization',
      'logo': {
        '@type': 'ImageObject',
        'url':  `${ baseUrl }${ '/img/drew-logo.png' }`,
        'width': '',
        'height': ''
      }
    },
    url: `${ baseUrl }${ path }`,
    description: content.field_subhead,
    keywords: []
  }

  // Add Author if
  if (content.field_byline_text) {
    schemaObject.author = {
      '@type': 'Person',
      'name': content.field_byline_text
    }
  }

  // Add Image Object Schema if main image exist
  if (content.field_image && content.field_image.social !== undefined) {
    schemaObject.image = {
      '@type': 'ImageObject',
      url: content?.field_image?.field_image?.social?.url,
      height: content?.field_image?.field_image?.social?.height,
      width: content?.field_image?.field_image?.social?.width
    }
    schemaObject.thumbnailUrl = content.field_image.lg.url;
  }

  // Add Video Object Schema if main video exist
  if (content.field_video && has(content, 'field_video.field_video_mpx_id.hls_streaming_url')) {
    schemaObject.video = {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      name: content.title,
      description: content.field_subhead,
      thumbnailUrl: content.field_video.field_video_mpx_id.poster_image || content.field_image.field_image.poster.url,
      thumbnail: {
        '@context': 'https://schema.org',
        '@type': 'ImageObject',
        height: 630,
        width: 1200,
        url: content.field_video.field_video_mpx_id.poster_image || content.field_image.field_image.poster.url
      },
      uploadDate: content.field_display_date,
      duration: `PT${ content.field_video.field_video_mpx_id.duration }S`,
      embedUrl: content.field_video.field_video_mpx_id.hls_streaming_url,
      publisher: {
        name: SiteSettings.siteName,
        '@type': 'Organization',
        logo: {
          '@type': 'ImageObject',
          url: '' // @TODO
        }
      }
    }
  }

  // Add Video Object Schema if main video exist
  else if (content.field_video_mpx_id && has(content, 'field_video_mpx_id.hls_streaming_url')) {
    schemaObject.video = {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      name: content.field_video_mpx_id.title,
      description: content.field_subhead,
      thumbnailUrl: content.field_image.field_image.poster.url,
      thumbnail: {
        '@context': 'https://schema.org',
        '@type': 'ImageObject',
        height: 630,
        width: 1200,
        url: content.field_image.field_image.poster.url // @TODO Verify
      },
      uploadDate: content.field_display_date,
      duration: `PT${ content.field_video_mpx_id.duration }S`,
      embedUrl: content.field_video_mpx_id.hls_streaming_url,
      publisher: {
        name: SiteSettings.siteName,
        '@type': 'Organization',
        logo: {
          '@type': 'ImageObject',
          url: '' // @TODO
        }
      }
    }
  }

  return schemaObject;
}

export const getFormattedSchedule = (initialSchedule) => {
  return Object.values(initialSchedule).map((item) => {
    // Parse the schedule object so we can add some additional data
    const day = item && item[0]
      ? item[0]
      : null;

    if (day) {
      const offset = moment().isDST() ? -420 : -480;
      const diff = moment().utcOffset(offset).startOf('day')
        .diff(moment(day.field_display_date).utcOffset(offset).startOf('day'), 'days');

      const displayDate = moment(day.field_display_date).format('M/D');
      let label = moment(day.field_display_date).format('ddd').toLowerCase()
      let mobileLabel = displayDate;

      switch(diff) {
        case 1:
          label = `yesterday ${ displayDate }`;
          break;
        case 0:
          label = 'today\'s show';
          mobileLabel = 'today'
          break;
        case -1:
          label = `tomorrow ${ displayDate }`;
          break;
        default:
          label = `${ moment.parseZone(day.field_display_date).format('ddd').toLowerCase() } ${ displayDate }`;
          break;
      }

      return {
        ...day,
        displayDate,
        day: moment.parseZone(day.field_display_date).format('dddd').toLowerCase(),
        label,
        mobileLabel
      };
    }

    return null;
  });
}

// Data Utils

export const getAlias = async (queryVars) => {
  const aliasData = await request(process.env.NEXT_PUBLIC_API_HOST || 'https://api-stage.thedrewbarrymoreshow.com/cmv_graphql/api/query', GET_ALIAS, queryVars);

  return aliasData;
}

export const getContentPaths = async (prefix, queryVars) => {
  const pathData = await request(process.env.NEXT_PUBLIC_API_HOST || 'https://api-stage.thedrewbarrymoreshow.com/cmv_graphql/api/query', GET_CONTENT_PATHS, queryVars);
  const paths = pathData.viewQuery.resultRow.map((item) => {
    const alias = item?.path?.replace?.(prefix, '').split('/');

    return(alias ? { params: { alias }} : null);
  }).filter((item) => item);

  return paths;
}

export const getShowfinder = async queryVars => {
  const data = await request(process.env.NEXT_PUBLIC_API_HOST || 'https://api-stage.thedrewbarrymoreshow.com/cmv_graphql/api/query', GET_SHOWFINDER, queryVars);

  return data;
}

export const getBlocksFromQueue = async queryVars => {
  const data = await request(process.env.NEXT_PUBLIC_API_HOST || 'https://api-stage.thedrewbarrymoreshow.com/cmv_graphql/api/query', GET_BLOCK_FROM_QUEUE, queryVars);

  return data;
}

export const getContentTypes = async queryVars => {
  const data = await request(process.env.NEXT_PUBLIC_API_HOST || 'https://api-stage.thedrewbarrymoreshow.com/cmv_graphql/api/query', GET_CONTENT_TYPE, queryVars);

  return data;
}

export const getTaxonomyTerms = async queryVars => {
  const data = await request(process.env.NEXT_PUBLIC_API_HOST || 'https://api-stage.thedrewbarrymoreshow.com/cmv_graphql/api/query', GET_TAXONOMY_TERMS, queryVars);

  return data;
}

export const getGalleries = async queryVars => {
  const data = await request(process.env.NEXT_PUBLIC_API_HOST || 'https://api-stage.thedrewbarrymoreshow.com/cmv_graphql/api/query', GET_GALLERIES, queryVars);

  return data;
}

export const getDrupalSettings = async () => {
  const data = await request(process.env.NEXT_PUBLIC_API_HOST || 'https://api-stage.thedrewbarrymoreshow.com/cmv_graphql/api/query', GET_SITE_SETTINGS, {});

  return data;
}

export const getPluggerToken = async () => {
  // NOTE: This method should only be called from node.
  if(!Buffer) {
    return false;
  }

  const options = {
    method: 'GET',
  };

  if(process.env.BASIC_AUTH_ACTIVE) {
    options.credentials = 'include';
    options.headers = {
      'Authorization': `Basic ${ Buffer.from(`${ process.env.BASIC_AUTH_USERNAME }:${ process.env.BASIC_AUTH_PASSWORD }`).toString('base64') }`
    };
  }

  const data = await fetch(`${ process.env.NEXT_PUBLIC_BASE_URL }/get-token`, options);

  const tokenJson = await data.json();

  return tokenJson;
}

export const min2secs = t => {
  const m = Math.floor(t % 3600 / 60).toString().padStart(2,'0');
  const s = Math.floor(t % 60).toString().padStart(2,'0');

  return `${m}:${s}`;
}

export const secondsToTimecode = s => {
  return new Date(1000 * s).toISOString().substr(11, 8)
}

export const getRecommendations = async () => {
  const data = await request(process.env.NEXT_PUBLIC_API_HOST || 'https://api-stage.thedrewbarrymoreshow.com/cmv_graphql/api/query', 
  GET_VIEW_RESULTS, {
    machine_name: "latest_videos",
    display: 'master',
    itemsPerPage: 20,
    offset: 0,
    viewArgs: [
      "video"
    ],
  });

  if(data) {
    return data.viewQuery?.resultRow;
  }

  return null;
}

export const constructPlaylist = (content, recommendations) => {
  if((!content?.field_video_mpx_id && !content?.field_video?.field_video_mpx_id)) {
    return null
  };

  const firstVideo = constructPlaylistItem(content.nid, content, 0, "hero");
  const recPlaylist = [];
  if(recommendations?.length && firstVideo) {
    recommendations.forEach((video, i) => {
      if (video.field_video_reference_id !== firstVideo.reference_id) {
        let videocopy = constructPlaylistItem(content.nid, video, i, 'hero')
        if (videocopy) {
          recPlaylist.push(videocopy)
        };
      }
    });
  }

  const resultPlaylist = [firstVideo, ...recPlaylist]

  // Add the proxy to all captions
  const plWithProxyCaptions = resultPlaylist.map((item) => ({
    ...item,
    captions: item?.captions
      ? `/vtt?file=${ item.captions }`
      : null
  }));

  return plWithProxyCaptions;
}

export const constructPlaylistItem = (contentNid, item, index, vidPlayerPos = 'hero') => {
  if(item && (item.field_video || item.field_video_mpx_id?.hls_streaming_url)) {
    const videoMpx = item.field_video_mpx_id || item.field_video?.field_video_mpx_id;
    const duration = parseInt(videoMpx?.duration, 10) || timeStringToSeconds(item.duration) || 60;
    const reference_id = item.field_video_reference_id || item.field_video?.field_video_reference_id || videoMpx?.reference_id ;

    return {
      image_url: item.field_image?.field_image?.sm?.url,
      title: item.title,
      stream_url: videoMpx?.hls_streaming_url,
      reference_id: reference_id,
      id: item.field_video_reference_id || videoMpx.mpx_id,
      hideAds: item.field_is_promotional || false,
      captions: item.field_video_captions?.path,
      duration: duration,
      metadata: {
        assetId: contentNid || item.nid,
        assetTitle: item.title,
        assetType: 'content_video',
        authorId: 'The Drew Barrymore Show Staff',
        authorName: 'The Drew Barrymore Show Staff',
        contentBrand: SiteSettings.siteName,
        deviceType: mobileCheck() ? 'mobile' : 'desktop',
        duration: duration,
        gaAccount: 'UA-1072526-1',
        mediaContentType: 'vod',
        mediaAutoplayCustom: true,
        mediaId: videoMpx.mpx_id || reference_id,
        mii: {
          viewtime: '10',
          refreshcap: '1000',
        },
        pageViewGuid: uuidv1(),
        videoReferenceId: item?.field_video_reference_id || item.reference_id,
        vidPlayerPos: 'hero',
        vidPlaylistPos: index || '0',
        videoTitle: item.title,
      }
    };
  }

  return null;
}

export const truncate = (str, num) => {
  return str.length <= num
    ? str
    : `${str.slice(0, num)}...`;
}

export const formatVideoMetaDataFromShortCode = (articleNodeId, videoData, shortCodeElement = {}) => {
  const mpxId = videoData.field_video_mpx_id || videoData.media_id || '';

  return {
    captions: shortCodeElement.field_caption ? `/vtt?file=${shortCodeElement.field_caption}` : null ,
    duration: videoData.duration,
    id: mpxId,
    image_url: urlWithQuery(`${videoData.poster_image}`,'width=640'),
    metadata: {
      assetId: articleNodeId,
      assetTitle: videoData.title,
      brandPlatformId: mobileCheck() ? 'drewbarrymore_site_mweb' : 'drewbarrymore_site_desktop',
      duration: videoData.duration,
      mediaAutoplayCustom: true,
      mediaContentType: 'vod',
      mediaId: videoData.reference_id || mpxId,
      mediaRegion: shortCodeElement.category,
      vidPlayerPos: 'body',
      vidPlaylistPos: 0,
      videoReferenceId: videoData.reference_id,
      videotitle: videoData.StoryHead || videoData.title
    },
    reference_id: videoData.reference_id,
    stream_url: videoData.hls_streaming_url,
    title: videoData.title,
  }
}

/**
 * getVideoKeywords
 * @category Helper Function
 * @param {object} video
 * @returns {string} - All relevant key words, seperated by commas for ad cust_params
 */
export const getVideoKeywords = (video) => {
  let keywords = [];
  // Properties in hero videos
  if (typeof video.field_video_mpx_id?.keywords === 'string') {
    keywords = [ ...keywords, ...video.field_video_mpx_id.keywords.split(',') ];
  }
  if (Array.isArray(video.field_video_mpx_id?.keywords)) {
    keywords = [ ...keywords, ...video.field_video_mpx_id.keywords ];
  }
  if (video.field_category) {
    keywords = [...keywords, video.field_category.name];
  }
  if (video.field_categories) {
    keywords = [...keywords, ...video.field_categories.map((category) => category.name)];
  }
  if (video.field_video_partner_tags) {
    keywords = [...keywords, ...video.field_video_partner_tags.split(',')];
  }
  if (video.field_editorial_tag) {
    keywords = [...keywords, ...video.field_editorial_tag.split(',')];
  }
  // Properties in embedded videos
  if (video.video && video.video.keywords) {
    keywords = [...keywords, ...video.video.keywords.split(',')];
  }
  if (video.category) {
    keywords = [...keywords, video.category];
  }
  keywords = keywords.map((keyword) => keyword.trim().replace(/ /g, '_').toLowerCase());
  return uniq(keywords).join(',');
};

export const isGiveAwayLink = (url) => {
  return url?.includes('giveaway');
}


/**
 * Converts CSS string to CSS object
 * 
 * @param {*} css 
 * @returns {object} - CSS object converted into key value pair.
 */
export const css2obj = (css) => {
  const css_object = {};
  css.replace(/([\w-]*)\s*:\s*([^;]*)/g, (match, key, value) => css_object[key] = value);
  
  return css_object;
};